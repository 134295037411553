import React from 'react';
import Title from './components/title.js';
import Projects from './components/projects.js';
import Footer from './components/footer.js'
import About from './components/about.js'
import "./assets/projects.css"
import './App.css';
import "./assets/index.css"
import "./assets/footer.css"
import "./assets/moon.css"



function App() {
  return (
    <div className="App">
      <Title/>
      <Projects/>
      <About/>
      <Footer/>
    </div>
  );
}

export default App;
