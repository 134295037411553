import React from 'react';
import $ from 'jquery'
import jQuery from 'jquery'



class About extends React.Component{
    
    componentDidMount(){

    }


    render(){
        return(
            <div>
                <div id = "aboutContainer">
                    <div id = "profile">
                        <img id = "myPic" src = "https://imgur.com/fiKc4MK.jpg"/>
                        <p id = "aboutDescription">
                            Hey! My name is Yacob Abusadah. I'm a programmer specializing in fullstack web development and I'm
                            currently a tutor, helping hundreds of students internationally!
                            If you'd like to work together, send me an email or message me on linkedIn!
                        </p>
                        <p id = "emailDesc">yacob.abusadah@gmail.com</p>
                    </div>
                </div>
            </div>
        )
    }
}

export default About;