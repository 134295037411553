import React from 'react';
import $ from 'jquery'
import jQuery from 'jquery'
import Moon from './moon.js'
// import{
//   Link
// } from 'react-router-dom';
var numFireflies = 200;
var numTrees = 1;
var trees = [];
// var treeLeft =  [50, 33, 830, 1127, 117, 918, 981, 401, 1130, 647, 19, 1100, 1188, 177, 902, 55, 111, 812, 1165, 1111, 228, 972, 565, 825, 794, 558, 794, 241, 178, 748, 1057, 25, 785, 652, 897, 650, 808, 914, 502, 342, 159, 367, 346, 333, 808, 663, 404, 98, 181, 977, 836, 690, 170, 70, 586, 235, 17, 824, 1136, 440];
// var treeBottom =[-43, -62, -77, -99, -67, -95, -97, -97, -61, -87, -78, -47, -78, -71, -63, -91, -89, -84, -76, -85, -49, -62, -85, -89, -96, -50, -47, -66, -65, -62, -93, -74, -73, -66, -41, -75, -47, -48, -42, -98, -40, -87, -89, -85, -50, -43, -74, -49, -71, -55, -45, -48, -67, -88, -45, -99, -57, -81, -74, -69];
var treeColor = "black";
var treeIndex = "1"
var randomBottom;
var randomTop;
var randomLeft;
var randomSize;
var fireflies = [];
var positions = [];
var transitionSecsBottom = []
var transitionTimers = []
var opacityArr = []
var windowHeight = $(window).height();
var githubImg = ""
var linkedImg = ""

function getRandom(){
  
  if(Math.round(Math.random() * 200) < 60){
    randomTop = Math.round(Math.random() * 2300) + windowHeight;
    randomBottom = 0;
    return;
  }else{
    randomTop = "";
  }
  randomBottom = Math.round(Math.random() * 90)
  randomLeft = Math.round(Math.random() * 99)
}


  var testArrLeft = []
  var testArrBot = []
for(let i = 0; i < 60; i++){
  testArrLeft.push(Math.round(Math.random() * 1200))
  testArrBot.push(Math.round(Math.random() * (-40 - -100) + -100))
}


function directionChange(){
  
  for(let i = 0; i < numFireflies; i++){
    
    if(Math.random() > .50){
      $(`#firefly${i}`).css("bottom", `${positions[i] + Math.round(Math.random() * 5)}%`)
    }else{
      $(`#firefly${i}`).css("bottom", `${positions[i] - Math.round(Math.random() * 5)}%`)
    }
  
}
}


function opacityChange(){
  
  for(let i = 0; i < numFireflies; i++){
    if(Math.random() > .10){
      $(`#firefly${i}`).css("opacity", `0`)
    }else{
      $(`#firefly${i}`).css("opacity", `1`)
    }
  
}
}


function pushOpacity(){
  for(let i = 0; i < numFireflies; i++){
      opacityArr.push(Math.random() * (2 - .5) + .5)
      transitionSecsBottom.push(Math.random() * (10 - 2) + 2)
    }
  
}
pushOpacity()



function randomizeClass(){
  if(Math.random() < .5){
    return "floatUp"
  }else{
    return "floatDown"
  }
}


    //initialize fireflies
for (var i = 0; i < numFireflies; i++) {
      
      (function(i){
        setInterval(() => {
          
          if($(`#firefly${i}`).hasClass("floatUp")){
            $(`#firefly${i}`).removeClass("floatUp")
            $(`#firefly${i}`).addClass("floatDown")
            $(`#firefly${i}`).css("bottom", `${positions[i] + 5}%`)
          }else{
            $(`#firefly${i}`).removeClass("floatDown")
            $(`#firefly${i}`).addClass("floatUp")
            $(`#firefly${i}`).css("bottom", `${positions[i] -  5}%`)
          }
          
      }, transitionSecsBottom[i] * 1000 )
    })(i);


    (function(i){
      setInterval(() => {
        
        if(Math.random() > .10){
          $(`#firefly${i}`).css("opacity", `0`)
        }else{
          $(`#firefly${i}`).css("opacity", `1`)
        }
        
    }, Math.random() * (2000 - 1000) + 1000 )
  })(i);
  
  
  
  getRandom()
  positions.push(randomBottom)
  randomSize = Math.round(Math.random() * (25 - 5 ) + 5 )
  fireflies.push(<div id = {`firefly${i}`} key={i} className = {`firefly ${randomizeClass()}`}
   style ={{bottom: `${randomBottom}%`, top: `${randomTop}px`, left: `${randomLeft}%`, zIndex: `${Math.round(Math.random() * (5 - 2) + 2)}`,
    height: `${randomSize}px`, width: `${randomSize}px`}} /> );
  

  
  
}

//initialize trees
// for (let i = 0; i < numTrees; i++){

  

//   if(treeBottom[i] > -60){
//     treeColor = "black";
//     treeIndex = "4"
//   }else if(treeBottom[i] > -80){
//     treeColor = "#050505";
//     treeIndex = "3"
//   }else{
//     treeColor = "#080808";
//     treeIndex = "2"
//   }

//   trees.push(
//     <div key = {i} className = "fullTree" style = {{"left": `${treeLeft[i]}px`, "bottom": `${treeBottom[i]}px`, "zIndex": `${treeIndex}`}}>
//       <div className = "tree" style = {{"borderLeft": "60px solid transparent","borderRight": "60px solid transparent", "borderBottom": `200px solid ${treeColor}` }} />
//       <div className = "tree" style = {{"borderLeft": "80px solid transparent","borderRight": "80px solid transparent", "borderBottom": `300px solid  ${treeColor}`}}/>
//       <div className = "tree" style = {{"borderLeft": "100px solid transparent","borderRight": "100px solid transparent", "borderBottom": `300px solid  ${treeColor}`}}/>
//       <div className = "tree" style = {{"borderBottom": `300px solid  ${treeColor}`, "z-index": "2" }}/>
//       <div className = "treeStump"/>
//     </div>
//   )
// }



class Title extends React.Component  {

  constructor(props){
    super(props);
    this.state = {
      githubImg: "",
      linkedImg: ""
    }
  }

  imageColors(){
    setTimeout(() => {
      if(window.innerWidth > 1100){
        this.setState({
          githubImg: "https://imgur.com/IIcmPH5.png",
          linkedImg: "https://imgur.com/wV9pMkT.png"
          
        })
      }else{
        this.setState({
          githubImg: "https://i.imgur.com/S36AWdL.png",
          linkedImg: "https://i.imgur.com/tECWxgp.png"
        })
      }
    },1)
      


    window.addEventListener('resize', () => {
        var windowWidth = window.innerWidth
        if(windowWidth > 1100){
          this.setState({
            githubImg: "https://imgur.com/IIcmPH5.png",
            linkedImg: "https://imgur.com/wV9pMkT.png"
          })
          
        }else{
          this.setState({
            githubImg: "https://i.imgur.com/S36AWdL.png",
            linkedImg: "https://i.imgur.com/tECWxgp.png"
          })
          
        }
      })
  }

  componentDidMount(){
    this.imageColors()
    

    for (var i = 0; i < numFireflies; i++) {
      $(`#firefly${i}`).css("opacity",`0`)
      $(`#firefly${i}`).css("transition", `bottom ease ${transitionSecsBottom[i]}s, opacity ease ${opacityArr[i]}s`)
    }
    
    
    
    
    setTimeout(() => {
      directionChange()
      // opacityChange()
    },1)

  }
  
  render(){
    
      
  return (
    <div>
      
      <div id = "titleScreenContainer">
        <div id = "titleScreen">
        <Moon />
          <div id = "contactContainer">
            <div id = "github">
              <a href = "https://github.com/KonoPowa"> <img id = "githubImg" src = {this.state.githubImg} /> </a>
            </div>
            <div id = "linked">
            <a href = "https://www.linkedin.com/in/yacob-abusadah-ba24641b6/"> <img id = "linkedImg" src = {this.state.linkedImg}/> </a>
            </div>
            <div id = "contact">
              {/* <p id = "email">
                yacob.abusadah@gmail.com
              </p> */}
            </div>
          </div>
          <div id = "titleContainer">
            <h1 id = "title">Yacob</h1>
            <p id = "titleDesc">Creative Web Developer</p>
          </div>
          
          <div id = "treeContainer">
            <div key = {i} className = "fullTree" style = {{"zIndex": "2"}}>
              <div className = "tree" style = {{"borderLeft": "60px solid transparent","borderRight": "60px solid transparent", "borderBottom": `200px solid ${treeColor}` }} />
              <div className = "tree" style = {{"borderLeft": "80px solid transparent","borderRight": "80px solid transparent", "borderBottom": `300px solid  ${treeColor}`}}/>
              <div className = "tree" style = {{"borderLeft": "100px solid transparent","borderRight": "100px solid transparent", "borderBottom": `300px solid  ${treeColor}`}}/>
              <div className = "tree" style = {{"borderBottom": `300px solid  ${treeColor}`, "z-index": "2" }}/>
              <div className = "treeStump"/>
            </div>
            <div id = "hill" />
          </div>
          <div id = "fireflyContainer">
            {/* <div className = "firefly"></div> */}
            {fireflies}
            {/* <div id = "test"></div> */}
            
            
          </div>
          
        </div>
      </div>
      
    </div>
  );
}
}

export default Title;
