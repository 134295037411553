import React from 'react';
import $ from 'jquery'
import jQuery from 'jquery'

class Footer extends React.Component  {
    constructor(props) {
      super(props);
  
      this.state = {
        
      }
    }
  
      componentDidMount(){
  
  }
        
      
      render(){
        
          
      return (
          <div>
              <div id = "footer">
              <div id = "copyright">
                    <p>
                        @Copyright 2020-2022
                    </p>
                </div>
                <div id = "name">
                    <p>
                        Yacob Abusadah
                    </p>
                </div>
                
              </div>
          </div>
      );
    }
    }
    
    export default Footer;