import React from 'react';
import $ from 'jquery'
import aiCharades from '../assets/images/aiCharades.png'
import workout from '../assets/images/workout.png'
import graph from '../assets/images/graph.png'
import history from '../assets/images/history.png'

// import{
//   Link
// } from 'react-router-dom';

var numberLines = 8;
var leftOrRight = "left"

var images = [
  history,
  aiCharades,
"https://imgur.com/6ZmGl3s.jpg",
"https://imgur.com/lS7GNqc.gif",
"https://imgur.com/MWkVNd7.png",
"https://i.imgur.com/T2c1Lod.png",
"https://imgur.com/3f6uIFt.png",
"https://imgur.com/JtxqsHb.png",

]

var headers = [
  "Strength Tracker",
  "AI Mad Libs",
  "SimianDB",
  "Minecraft Bot",
  "Queues",
  "Calculator",
  "Pomodoro Clock",
  "Drum Machine",
]


var content  = [
  <div className = "content">
  This app makes it easy to track and record the user's workouts, including the specific exercises performed, the weight 
  lifted or resistance used, and the number of sets and reps completed. This allows the user to keep a comprehensive 
  record of their training over time, which is essential for monitoring progress and identifying areas for improvement.
  <div className = "projectButton">
    <a className = "buttonAnchor" href = "https://github.com/KonoPowa/strength-tracker-readme/blob/main/README.md" target = "_blank">
      <p className = "buttonText">Project</p>
    </a>
  </div>
</div>,
  <div className = "content">
  Madlibs is a fun and interactive game where the player inputs various parts of speech, such as nouns, verbs, and adjectives,
   into a pre-written story. I collaborated with a client to create a version of that with images, where you do the same but it instead generates an AI image.
    The game can be played alone or with friends, 
  and can provide endless entertainment.

  <div className = "projectButton">
    <a className = "buttonAnchor" href = "https://www.aicharades.com/" target = "_blank">
      <p className = "buttonText">Project</p>
    </a>
  </div>
</div>,
  <div className = "content">
  CRUD application for my friends to submit pictures/YouTube links of simians or apes.
	If a YouTube link is submitted the card component will conditionally render an embedded YouTube video, if an image is uploaded it will display that instead.
  If you click the ook button you will hear the call of a Siamang Gibbon, truly groundbreaking and important functionality.

  <div className = "projectButton">
    <a className = "buttonAnchor" href = "https://simian-database.herokuapp.com/" target = "_blank">
      <p className = "buttonText">Project</p>
    </a>
  </div>
</div>,
  <div className = "content">
  This bot uses the OpenComputers mod which adds programmable computers to Minecraft.
	Takes length/width/height to create a platform, wall, or castle turret.
	Input your dimensions and take a nap!

  <div className = "projectButton">
    <a className = "buttonAnchor" href = "https://github.com/KonoPowa/minecraftBot" target = "_blank">
      <p className = "buttonText">Project</p>
    </a>
  </div>
</div>,
  <div className = "content">
    	Queues at the time was focused on Covid prevention through the use of computer vision data to determine if areas were following Covid guidelines
       (social distancing, number of people in the area, etc.).
      I Focused on visualizing our computer vision data for our
       customers through the use of graphs and improving the UI/UX as one of the main 
       front-end engineers.

  </div>,
  <div className = "content">
    This javascript calculator is a technical project of mine. I created a fully functional calculator
    that has been thoroughly debugged. I gave it a modern look and some attention to detail to make it nice to look at.
    <div className = "projectButton">
      <a className = "buttonAnchor" href = "https://konopowa.github.io/javascript-calculator/" target = "_blank">
        <p className = "buttonText">Project</p>
      </a>
    </div>
  </div>,
  <div className = "content">
    My pomodoro clock is a method used for efficient use of time. There's an about page on it
    that gives a good explanation. I hand animated this by drawing one image and then tracing over it to create the second image. Using javascript,
    I then had them appear and disappear at an interval; creating an animation. 
    <div className = "projectButton">
      <a className = "buttonAnchor" href = "https://konopowa.github.io/pomodoro-clock/#/" target = "_blank">
        <p className = "buttonText">Project</p>
      </a>
    </div>
  </div>,
  <div className = "content">
    Play some sick beats on my drum machine! It has 18 sounds over 2 kits and a display to show what sound/what kit you're on.
    It has a changing background color and a changing shade color to match it, just because.

  <div className = "projectButton">
    <a className = "buttonAnchor" href = "https://konopowa.github.io/drum-machine/" target = "_blank">
      <p className = "buttonText">Project</p>
    </a>
  </div>
</div>,
  ]



var lines = []

for(let i = 0; i < numberLines; i++){

    if(i === 0){ //had to hard code this because past me was bad at coding, THIS IS SPECIFICALLY FOR THE PHONE SECTION
      leftOrRight = "right"
      lines.push(
          <div className = "lineContainer" id = {`line${i}`}>
            <div className = "project">
                <img src = {graph} className = "projectImg" width = "25%" height = "auto"/> 
                <img src = {images[i]} className = "projectImg"  style = {{marginLeft: '50px'}}  width = "25%" height = "auto"/> 
                <img src = {workout} className = "projectImg"  style = {{marginLeft: '50px'}}  width = "25%" height = "auto"/> 
            </div>
            <div className = "projectDesc">
                <h2 className = "header">{headers[i]}</h2>
               {content[i]}
            </div>        
          </div>
      )
    } else if(leftOrRight === "left"){
        leftOrRight = "right"
        lines.push(
            <div className = "lineContainer" id = {`line${i}`}>
              <div className = "project">
                  <img src = {images[i]} className = "projectImg"  width = "100%" height = "auto"/> 
              </div>
              <div className = "projectDesc">
                  <h2 className = "header">{headers[i]}</h2>
                 {content[i]}
              </div>        
            </div>
        )
    
    }else{
        leftOrRight = "left"
        lines.push(
            <div className = "lineContainer2" id = {`line${i}`}>
              <div className = "projectDesc2">
                <h2 className = "header">{headers[i]}</h2>
                {content[i]}
              </div>
              
              <div className = "project2">
                <img src = {images[i]} className = "projectImg" />
              </div>
            </div>
        )
    }
    
}
// src = "https://static.invenglobal.com/upload/image/2018/11/05/i1541487768447862.jpeg"
var opacity;


const checkpoint1 = 550;
const checkpoint2 = 1050;
const checkpoint3 = 1550;
const checkpoint4 = 2050;
const checkpoint5 = 2550;
const checkpoint6 = 3050;
const checkpoint7 = 3550;
const checkpoint8 = 4050;
 
window.addEventListener("scroll", () => {
  var currentScroll = window.pageYOffset;
  if (currentScroll >= checkpoint1) {
    $("#line0").addClass("animate")
  }
  if(currentScroll >= checkpoint2){
    $("#line1").addClass("animate")
  }
  if(currentScroll >= checkpoint3){
    $("#line2").addClass("animate")
  }

  if(currentScroll >= checkpoint4){
    $("#line3").addClass("animate")
  }

  if(currentScroll >= checkpoint5){
    $("#line4").addClass("animate")
  }

  if(currentScroll >= checkpoint6){
    $("#line5").addClass("animate")
  }
  if(currentScroll >= checkpoint7){
    $("#line6").addClass("animate")
  }
  if(currentScroll >= checkpoint8){
    $("#line7").addClass("animate")
  }
  
});







class Projects extends React.Component  {
  constructor(props) {
    super(props);

    this.state = {
      lines: lines,
    }
  }

    componentDidMount(){

    
     
         
          
        
}
      
    
    render(){
      
        
    return (
        <div id = "projectsContainer">
            <div></div>
            {this.state.lines}
        </div>
    );
  }
  }
  
  export default Projects;
  