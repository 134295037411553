import React from 'react';
import $ from 'jquery'
import jQuery from 'jquery'


class Moon extends React.Component{




    render(){
        return(
            <div>
                <div id = "moon">
                    <div id = "crater"/>
                    <div id = "crater2"/>
                    <div id = "crater3"/>
                </div>
            </div>
        )
    }
}

export default Moon